<template>
  <div id="homePage">
    <!-- <header>
      <div class="avatar">
        <img src="~@/assets/img/avatar.png" alt="管理员" />
      </div>
      <p>{{ hello }}{{ userInfo.username }}！欢迎访问{{title}}！</p>
    </header> -->
    <totalDataView></totalDataView>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import totalDataView from './total'
export default {
  name: 'mainPage',
  components: { totalDataView },
  data () {
    return {
      systemInfo: null,
      title: process.env.VUE_APP_TITLE
    }
  },
  computed: {
    ...mapGetters (['version']),
    ...mapState('system', ['userInfo']),
    hello () {
      let hour = (new Date()).getHours()
      if (hour > 4 && hour < 11) return '早上好，'
      else if (hour < 15) return '午安，'
      else if (hour < 20) return '下午好，'
      else return '晚安，' 
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss">
#homePage {
  header {
    background-color: #fff;
    margin:-10px;
    margin-bottom: 15px;
    padding: 30px;

    .avatar {
      float: left;
      width: 48px;
      height:48px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid darken(#fff, 5%);
      margin-right: 20px;

      img {
        display: block;
        width:100%;
        height:100%;
      }
    }

    p {
      margin:0;
      font-size: 20px;
      line-height: 48px;
    }
  }

  .systemInfoBox {
    table {
      width: 100%;

      th, td {
        padding: 10px;
      }

      th {
        text-align: right;
        width: 10em;
      }
    }
  }
}
</style>